<template>
  <v-container>
    <h1 v-html="$t('search_smp_encashment_row_title')"></h1>
    <v-row class="row search spacer-md">
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-s-m-p-encashment-rows ref="search-s-m-p-encashment-rows" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contact ref="search-contact" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-farm ref="search-farm" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <searchContactAttributeAndRelation
          name="searchcardAttributeAndRelation"
          store-name="SearchContactAttributesRelationsStore"
          :show-picker="true"
          ref="search-contact-attributes"
          @search="search"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-legal-entity ref="search-legal-entity" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search"><span v-html="$t('smp_encashment_row_search_masterdata_search')" /></v-btn>
    <v-btn color="secondary" class="margin-left" @click="reset"><span v-html="$t('smp_encashment_row_search_masterdata_reset')" /></v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ props }">
        <v-btn class="button-margin-left" id="search_smp_encashment_rows_bulk_changes" color="secondary" v-bind="props" :loading="loadingActions">
          <span v-html="$t('search_smp_encashment_rows_bulk_changes')" />
          <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="bulkReleaseForCascadeRunStep1" id="search_smp_encashment_rows_bulk_release">
          <v-list-item-title v-html="$t('search_smp_encashment_rows_bulk_release')"></v-list-item-title>
        </v-list-item>
        <v-list-item @click="bulkTerminateStep1" id="search_smp_encashment_rows_bulk_terminate">
          <v-list-item-title v-html="$t('search_smp_encashment_rows_bulk_terminate')"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu offset-y>
      <template v-slot:activator="{ props }">
        <v-btn class="button-margin-left" id="smp_encashment_dropdowns" color="secondary" v-bind="props" :loading="loadingDownload">
          <span v-html="$t('download')" />
          <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item id="search_smp_encashment_rows_download">
          <v-list-item-title v-html="$t('search_smp_encashment_rows_download')"></v-list-item-title>
        </v-list-item>
        <v-list-item @click="download" id="search_smp_encashment_rows_download_wide">
          <v-list-item-title v-html="$t('search_smp_encashment_rows_download_wide')"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-data-table-server
      :headers="fields"
      :items="smpEncashmentRowItems"
      :loading="loading"
      :items-length="totalElements"
      :multi-sort="true"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      density="compact"
    >
      <template v-slot:[`item.edit`]="{ item }">
        <div class="link" @click="openDialog(item)">
          <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
        </div>
      </template>
      <template v-slot:[`item.smpEncashmentRowEncashmentId`]="{ item }">
        <template v-if="item.farmContactId && $privileges.has({ path: '/contacts', permission: 'read' })">
          <router-link
            :to="{
              name: 'contacts_edit',
              params: { id: item.farmContactId, tab: 'encashmentAndInvoices' }
            }"
            class="tableaction link"
          >
            {{ item.smpEncashmentRowEncashmentId }}
          </router-link>
        </template>
        <template v-else>
          {{ item.smpEncashmentEntityIdent }}
        </template>
      </template>
      <template v-slot:[`item.smpEncashmentRunId`]="{ item }">
        <template v-if="item.smpEncashmentRunId">
          {{ item.smpEncashmentRunCreation ? format(item.smpEncashmentRunCreation, 'MM.yyyy') : '-' }}
          ({{ item.smpEncashmentRunId }})
        </template>
      </template>
      <template v-slot:[`item.smpEncashmentEntityName`]="{ item }">
        <template v-if="item.smpEncashmentEntityIdent || item.smpEncashmentEntityIdent === 0">
          {{ item.smpEncashmentEntityIdent + ' ' + item.smpEncashmentEntityName }}
        </template>
        <template v-else>
          <span v-html="$t('smp_encashment_search_not_assigned')"></span>
        </template>
      </template>
      <template v-slot:[`item.farmAgisId`]="{ item }">
        <template v-if="item.farmContactId && $privileges.has({ path: '/contacts', permission: 'read' })">
          <router-link
            :to="{
              name: 'contacts_edit',
              params: { id: item.farmContactId, tab: 'overview' }
            }"
            class="tableaction link"
            >{{ item.farmAgisId }}
          </router-link>
          {{ item['farmTypeName' + $getUpLang()] }}
        </template>
        <template v-else>
          {{ item['farmTypeName' + $getUpLang()] }}
        </template>
      </template>
      <template v-slot:[`item.farmerAgisId`]="{ item }">
        <template v-if="item.farmerContactId && $privileges.has({ path: '/contacts', permission: 'read' })">
          <router-link
            :to="{
              name: 'contacts_edit',
              params: { id: item.farmerContactId, tab: 'overview' }
            }"
            class="tableaction link"
            >{{ item.farmerAgisId }}
          </router-link>
        </template>
        <template v-else>
          {{ item.farmerAgisId }}
        </template>
      </template>
      <template v-slot:[`item.dairyName`]="{ item }">
        <template v-if="item.dairyContactId && $privileges.has({ path: '/contacts', permission: 'read' })">
          {{ item.dairyName }} -
          <router-link
            :to="{
              name: 'contacts_edit',
              params: { id: item.dairyContactId, tab: 'overview' }
            }"
            class="tableaction link"
            >{{ item.dairyIdent }}
          </router-link>
        </template>
        <template v-else>
          {{ item.dairyName }}
        </template>
      </template>
      <template v-slot:[`item.smpEncashmentRunValidOn`]="{ item }">
        <template v-if="item.smpEncashmentRunValidOn">
          <span>{{ format(item.smpEncashmentRunValidOn, 'MM.yyyy') }}</span>
        </template>
      </template>
      <template v-slot:[`item.smpEncashmentRowQuantity`]="{ item }">
        {{ $formatNumber(item['smpEncashmentRowQuantity'], 1) }}
      </template>
      <!--      todo absprung vermarktete milch, WER-71-->
      <template v-slot:[`item.smpEncashmentRowVersionNo`]="{ item }">
        <template v-if="item.dairyContactId && $privileges.has({ path: '/contacts', permission: 'read' }) && item.smpEncashmentRowVersionNo">
          <router-link
            :to="{
              name: 'contacts_edit',
              params: { id: item.farmContactId, tab: 'encashmentAndInvoices' }
            }"
            class="tableaction link"
            >{{ item.smpEncashmentRowVersionNo }}
          </router-link>
        </template>
      </template>
    </v-data-table-server>
  </v-container>
  <!-- Bemerkungen/Anhang/Verlauf -->
  <!--  todo set links und priv, WER-76--->
  <v-dialog :retain-focus="false" v-model="dialog" max-width="75%" id="encashment_history_dialog">
    <v-card>
      <v-card-title>{{ selectedSmpEncashmentRowEncashmentId }} - Work in progress</v-card-title>
      <v-card-text>
        <rdhComponent
          :show="$privileges.has({ path: '/smpEncashments', permission: 'history' })"
          :history_show="true"
          :remarks_show="true"
          :documents_show="true"
          :remarks_tag="'segmentations_' + $route.params.id"
          :documents_tag="'segmentations_' + $route.params.id"
          :history_url="'/contacts/' + $route.params.id + '/smpEncashments'"
          ref="rdh"
        ></rdhComponent>
      </v-card-text>
      <v-card-actions>
        <v-btn id="close-btn" @click="closeDialog()" type="submit"><span v-html="$t('close')" /> </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--  release-->
  <v-dialog :retain-focus="false" v-model="dialogBulkChangeRelease" max-width="40%" id="encashment_bulk-change">
    <v-card>
      <v-card-title><span v-html="$t('search_smp_encashment_rows_bulk_release')" /></v-card-title>
      <v-card-text>
        <v-alert v-if="bulkChangeCounter > 0" border="top" colored-border type="info" elevation="2">
          {{ bulkChangeCounter }}
          <span v-html="$t('search_smp_encashment_rows_bulk_release_text')" />
        </v-alert>
        <v-alert v-else border="top" colored-border type="info" elevation="2">
          <span v-html="$t('search_smp_encashment_rows_bulk_release_no_values')" />
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="bulkChangeCounter > 0"
          id="close-btn"
          color="primary"
          @click="bulkReleaseForCascadeRunStep2()"
          :loading="loadingBulkChangeRelease"
          type="submit"
          ><span v-html="$t('search_smp_encashment_rows_bulk_release_button')" />
        </v-btn>
        <v-btn id="close-btn" color="secondary" @click="closeDialogBulkChangeRelease()" type="submit"><span v-html="$t('close')" /></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--  terminate-->
  <v-dialog :retain-focus="false" v-model="dialogBulkChangeTerminate" max-width="40%" id="encashment_bulk-change-terminate">
    <v-card>
      <v-card-title><span v-html="$t('search_smp_encashment_rows_bulk_terminate')" /></v-card-title>
      <v-card-text>
        <v-alert v-if="bulkChangeCounter > 0" border="top" colored-border type="info" elevation="2">
          {{ bulkChangeCounter }}
          <span v-html="$t('search_smp_encashment_rows_bulk_terminate_text')" />
        </v-alert>
        <v-alert v-else border="top" colored-border type="info" elevation="2">
          <span v-html="$t('search_smp_encashment_rows_bulk_terminate_no_values')" />
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn :v-if="bulkChangeCounter > 0" id="close-btn" color="primary" @click="bulkTerminateStep2()" :loading="loadingBulkChangeTerminate" type="submit"
          ><span v-html="$t('search_smp_encashment_rows_bulk_terminate_button')" />
        </v-btn>
        <v-btn id="close-btn" color="secondary" @click="closeDialogBulkChangeTerminate()" type="submit"><span v-html="$t('close')" /></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, reactive, computed, onMounted, watch, getCurrentInstance } from 'vue'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchContactAttributeAndRelation from '@/components/searchCards/searchContactAttributeAndRelation.vue'
import SearchSMPEncashmentRows from '@/components/searchCards/searchSmpEncashmentRows.vue'
import { Term } from '@/services/term'
import { apiURL, emitter } from '@/main'
import { fileCreator, showError } from '@/services/axios'
import rdhComponent from '@/components/rdh/rdhComponent.vue'
import { useSearchSmpEncashmentRowsStore } from '@/store/SearchSmpEncashmentRowsStore'
import { format } from 'date-fns'
import { useSearchContactAttributesRelationsStore } from '@/store/SearchContactAttributesRelationsStore'

const smpEncashmentRowItems = ref<EncashmentRowItem[]>([])
const totalElements = ref(0)
const loading = ref(false)
const loadingDownload = ref(false)
const loadingActions = ref(false)
const loadingBulkChangeRelease = ref(false)
const loadingBulkChangeTerminate = ref(false)
const dialog = ref(false)
const dialogBulkChangeRelease = ref(false)
const dialogBulkChangeTerminate = ref(false)
const bulkChangeCounter = ref(0)
const selectedSmpEncashmentRowEncashmentId = ref(0)
const { proxy } = getCurrentInstance()

interface EncashmentRowItem {
  smpEncashmentRowQuantity: number
  smpEncashmentRowVersionNo: number
  smpEncashmentRowEncashmentId: number
  smpEncashmentRowNumberOfInvoices: number
  smpEncashmentStatus: string
  smpEncashmentRunValidOn: string
  smpEncashmentRunId: number
  smpEncashmentRunCreation: string
  smpEncashmentEntityId: number
  smpEncashmentEntityIdent: number
  smpEncashmentEntityName: string
  farmContactId: number
  farmAgisId: number
  farmTypeNameDe: string
  farmTypeNameFr: string
  farmTypeNameIt: string
  farmerContactId: number | null
  farmerAgisId: number | null
  farmerName: string | null
  farmerZip: string | null
  farmerLocality: string | null
  dairyContactId: number
  dairyIdent: number
  dairyName: string
  dairyTypeNameDe: string
  dairyTypeNameFr: string
  dairyTypeNameIt: string
}

const fields = reactive(
  [
    {
      key: 'edit',
      sortable: false,
      width: '2%',
      isReduced: true
    },
    // Inkasso-ID
    {
      title: proxy.$t('smp_encashment_row_search_encashment_entity_id'),
      key: 'smpEncashmentRowEncashmentId',
      sortable: true,
      isReduced: true
    },
    // Status Inkasso
    {
      title: proxy.$t('smp_encashment_row_search_encashment_status'),
      key: `smpEncashmentStatusName${proxy.$route.params.upLang}`,
      sortable: true,
      isReduced: true
    },
    // Kaskade (date + id)
    {
      title: proxy.$t('smp_encashment_row_search_encashment_cascade'),
      key: 'smpEncashmentRunId',
      sortable: true,
      isReduced: true
    },
    // INK-Stelle
    {
      title: proxy.$t('smp_encashment_row_search_encashment_entity'),
      key: 'smpEncashmentEntityName',
      sortable: true,
      isReduced: true
    },
    // in Anz. RE
    {
      title: proxy.$t('smp_encashment_row_search_in_invoice_quantity'),
      key: 'smpEncashmentRowNumberOfInvoices',
      align: 'end',
      sortable: true,
      isReduced: true
    },
    // AGIS-ID Betrieb
    {
      title: proxy.$t('smp_encashment_row_search_agis_id'),
      key: 'farmAgisId',
      sortable: true,
      isReduced: true
    },
    // AGIS-ID Person
    {
      title: proxy.$t('smp_encashment_row_search_agis_id_p'),
      key: 'farmerAgisId',
      sortable: true,
      isReduced: true
    },
    // Name / Vorname
    {
      title: proxy.$t('smp_encashment_row_search_name1'),
      key: 'farmerName',
      sortable: true,
      isReduced: true
    },
    // Verwerter/MBH
    {
      title: proxy.$t('smp_encashment_row_search_dairy_mbh'),
      key: 'dairyName',
      sortable: true,
      isReduced: true
    },
    // Info
    {
      title: proxy.$t('smp_encashment_row_search_dairy_type'),
      key: `dairyTypeName${proxy.$route.params.upLang}`,
      sortable: true,
      isReduced: true
    },
    // MPD Datum
    {
      title: proxy.$t('smp_encashment_row_search_mpd_date'),
      key: 'smpEncashmentRunValidOn',
      sortable: true,
      isReduced: true
    },
    // MPD Menge
    {
      title: proxy.$t('smp_encashment_row_search_mpd_quantity'),
      key: 'smpEncashmentRowQuantity',
      align: 'end',
      sortable: true,
      isReduced: true
    },
    // MPD L-Nr.
    {
      title: proxy.$t('smp_encashment_row_search_mpd_run'),
      key: 'smpEncashmentRowVersionNo',
      sortable: true,
      isReduced: true
    }
    // todo adjust privileges to 'invoice' & 'in Anz. RE' WER-71
  ].filter(
    (el) =>
      proxy.$privileges.has({
        path: '/smpEncashmentRows',
        permission: 'read'
      }) && el.isReduced
  )
)

const searchSmpEncashmentRowsStore = useSearchSmpEncashmentRowsStore()

const queryParams = computed(() => ({
  smpEncashmentEntityIds: searchSmpEncashmentRowsStore.SmpEncashmentEntityIds.join(','),
  smpEncashmentRowId: searchSmpEncashmentRowsStore.smpEncashmentRowId,
  status: searchSmpEncashmentRowsStore.status,
  runDate: searchSmpEncashmentRowsStore.runDate,
  runId: searchSmpEncashmentRowsStore.runId,
  productionQuantityMin: searchSmpEncashmentRowsStore.productionQuantityMin,
  productionQuantityMax: searchSmpEncashmentRowsStore.productionQuantityMax,
  productionValidOnMin: searchSmpEncashmentRowsStore.productionValidOnMin,
  productionValidOnMax: searchSmpEncashmentRowsStore.productionValidOnMax,
  presentInEncashments: searchSmpEncashmentRowsStore.presentInEncashments,
  smpContribution: searchSmpEncashmentRowsStore.smpContribution
}))

const term = computed(() => [
  ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
  ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items),
  ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
  ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items)
])

const query = computed(() => ({
  term: Term.stringify(term.value),
  ...queryParams.value,
  ...proxy.getJavaPageOptions({ sort: ['smpEncashmentRunId, asc', 'smpEncashmentStatus, asc', 'smpEncashmentRunValidOn, asc', 'farmerName, asc'] })
}))

const search = async () => {
  proxy.$router.push({ path: proxy.$route.path, query: query.value }).catch(() => {
    load()
  })
}

const load = async () => {
  loading.value = true
  try {
    smpEncashmentRowItems.value = []
    const response = await proxy.axios.get(apiURL + '/smpEncashmentRows/find', { params: query.value })
    const data: EncashmentRowItem[] = response.data.content
    smpEncashmentRowItems.value = Array.isArray(data) ? data : []
    totalElements.value = response.data.totalElements
  } catch (e) {
    showError(e)
  } finally {
    loading.value = false
  }
}

const reset = () => {
  searchSmpEncashmentRowsStore.$reset()
  proxy.$refs['search-contact'].reset()
  proxy.$refs['search-farm'].reset()
  proxy.$refs['search-contact-attributes'].reset()
  proxy.$refs['search-legal-entity'].reset()
  proxy.$refs['search-dairy'].reset()
}

const download = async () => {
  loadingDownload.value = true
  try {
    const response = await proxy.axios.get(apiURL + '/smpEncashmentRows/wide/export', {
      params: query.value,
      headers: {
        Accept: 'application/msexcel'
      },
      responseType: 'blob'
    })
    // if loading takes too long
    if ((await response.status) === 204) {
      emitter.emit('openDownloadInfoDialog')
    } else {
      fileCreator(await response, 'abzugZuschlag.xlsx')
    }
  } catch (e) {
    let responseObj = await e.response.data.text()
    showError({ response: { data: JSON.parse(responseObj) } })
  } finally {
    loadingDownload.value = false
  }
}

const bulkReleaseForCascadeRunStep1 = async () => {
  loadingActions.value = true
  let params = query.value
  params.justCount = true
  try {
    const count = await proxy.axios.get(apiURL + '/smpEncashmentRows/approve', { params: params })
    if (await count) {
      bulkChangeCounter.value = count.data.count
      dialogBulkChangeRelease.value = true
    }
  } catch (e) {
    showError(e)
  } finally {
    loadingActions.value = false
  }
}

const bulkReleaseForCascadeRunStep2 = async () => {
  loadingBulkChangeRelease.value = true
  let params = query.value
  params.justCount = false
  try {
    const response = await proxy.axios.get(apiURL + '/smpEncashmentRows/approve', { params: params })
    if (await response) {
      emitter.emit(
        'toastSuccess',
        `${proxy.$t('masterdata_legal_entity_edit_toast_success')} - Anzahl: ${response.data.count} Lauf-Id: ${response.data.smpEncashmentRunId}`
      )
    }
  } catch (e) {
    showError(e)
  } finally {
    loadingBulkChangeRelease.value = false
    dialogBulkChangeRelease.value = false
    await load()
  }
}

const bulkTerminateStep1 = async () => {
  loadingActions.value = true
  let params = query.value
  params.justCount = true
  try {
    const count = await proxy.axios.get(apiURL + '/smpEncashmentRows/close', { params: params })
    if (await count) {
      bulkChangeCounter.value = count.data.count
      dialogBulkChangeTerminate.value = true
    }
  } catch (e) {
    showError(e)
  } finally {
    loadingActions.value = false
  }
}

const bulkTerminateStep2 = async () => {
  loadingBulkChangeTerminate.value = true
  let params = query.value
  params.justCount = false
  try {
    const response = await proxy.axios.get(apiURL + '/smpEncashmentRows/close', { params: params })
    if (await response) {
      emitter.emit('toastSuccess', `${proxy.$t('masterdata_legal_entity_edit_toast_success')} - Anzahl: ${response.data.count}`)
    }
  } catch (e) {
    showError(e)
  } finally {
    loadingBulkChangeTerminate.value = false
    dialogBulkChangeTerminate.value = false
    await load()
  }
}

const openDialog = (item: typeof SearchSMPEncashmentRows) => {
  dialog.value = true
  selectedSmpEncashmentRowEncashmentId.value = item.smpEncashmentRowEncashmentId
}

const closeDialog = () => {
  dialog.value = false
  selectedSmpEncashmentRowEncashmentId.value = 0
}

const closeDialogBulkChangeRelease = () => {
  dialogBulkChangeRelease.value = false
}

const closeDialogBulkChangeTerminate = () => {
  dialogBulkChangeTerminate.value = false
}

watch(
  () => proxy.$route.query,
  () => {
    load()
  },
  { deep: true }
)

onMounted(() => {
  if (proxy.$route.query.size) load()
})
</script>

<style scoped lang="scss"></style>
